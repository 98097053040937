@import url('https://fonts.googleapis.com/css2?family=Figtree:ital,wght@0,300..900;1,300..900&family=Kalnia+Glaze:wght@100..700&family=Playfair+Display:ital,wght@0,400..900;1,400..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Figtree:ital,wght@0,300..900;1,300..900&family=Kalnia+Glaze:wght@100..700&family=Lexend:wght@100..900&family=Playfair+Display:ital,wght@0,400..900;1,400..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');


.lexend-bold {
  font-family: "Lexend", sans-serif;
  font-optical-sizing: auto;
  font-weight: 700; /* Bold */
  font-style: normal;
}
.lexend-semibold {
  font-family: "Lexend", sans-serif;
  font-optical-sizing: auto;
  font-weight: 600; 
  font-style: normal;
}
.lexend-normal {
  font-family: "Lexend", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400; 
  font-style: normal;
}

.lexend-light {
  font-family: "Lexend", sans-serif;
  font-optical-sizing: auto;
  font-weight: 300; /* Light */
  font-style: normal;
}

.lexend-medium {
  font-family: "Lexend", sans-serif;
  font-optical-sizing: auto;
  font-weight: 500; /* Medium */
  font-style: normal;
}






.fgt-ff-normal {
    font-family: "Figtree", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400; 
    font-style: normal;
}
.fgt-ff-light {
    font-family: "Figtree", sans-serif;
    font-optical-sizing: auto;
    font-weight: 300; 
    font-style: normal;
}
.fgt-ff-medium {
    font-family: "Figtree", sans-serif;
    font-optical-sizing: auto;
    font-weight: 500; 
    font-style: normal;
}
.fgt-ff-semibold {
    font-family: "Figtree", sans-serif;
    font-optical-sizing: auto;
    font-weight: 600; 
    font-style: normal;
}
.fgt-ff-bold {
    font-family: "Figtree", sans-serif;
    font-optical-sizing: auto;
    font-weight: 700; 
    font-style: normal;
}

.icon-colors {
    fill: #E6A358; 
  }

  .slick-dots {
    position: absolute;
    bottom: -64px !important;
    display: block;
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center;
  }
  .slick-dots li button {
    font-size: 0;
    line-height: 0;
    display: block;
    width: 20px;
    height: 20px;
    padding: 5px;
    cursor: pointer;
    color: #E6A358 !important;
    border: 0;
    outline: none;
    background: transparent;
  }
  .slick-dots li.slick-active button::before {
    opacity: .75;
    color: #E6A358 !important;
  }
  html {
    scroll-behavior: smooth;
  }
  
  .animate-slide-in {
    animation: slide-in 0.3s ease-in-out forwards;
  }
  
  .animate-slide-out {
    animation: slide-out 0.3s ease-in-out forwards;
  }
  
  @keyframes slide-in {
    from {
      transform: translateX(-100%);
    }
    to {
      transform: translateX(0);
    }
  }
  
  @keyframes slide-out {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(-100%);
    }
  }
  /* Target xs breakpoint */
@media (min-width: 318px) and (max-width: 768px) {
  /* Hide <br> tags with class 'xs-hide' on xs screens */
  .xs-hide {
    display: none;
  }
}
